<template>
    <div>
        <font-awesome-icon icon="bed"></font-awesome-icon>
        {{ row.item.custom_beds || 0 }} {{ $t('article.text.beds') }}
        <template v-if="row.item.custom_reserve_bed"> {{ $t('article.text.reserve') }}</template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>